






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Quản lý teams",
          urlEnabled: true,
          search: {},
          filters: {
            filters: {
              departmentId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Phòng",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("departments", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              disabled: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: [
                    { text: "Hoạt động", value: false },
                    { text: "Dừng hoạt động", value: true },
                  ],
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          displayFields: {
            index: {
              text: "STT",
              options: {
                index: true,
              },
            },
            name: {
              text: "Tên",
              sortable: true,
            },
            description: {
              text: "Mô tả",
              sortable: true,
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            department: {
              text: "Phòng",
              options: {
                subProp: "department.name",
                label: true,
                sortBy: "departmentId",
              },
            },
            disabled: {
              text: "Trạng thái",
              options: {
                label(value) {
                  return (value && "Dừng hoạt động") || "Hoạt động";
                },
                attrs(value) {
                  return {
                    color: value ? "red" : "green",
                  };
                },
              },
            },
            tmdt: {
              text: "TMDT",
              sortable: true,
              options: {
                boolean: true,
              },
            },
            startTime: {
              text: "Ngày bắt đầu",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            avgTvDuration: {
              text: "Thời gian thử việc trung bình",
              options: {
                preTransform(value) {
                  if (value) {
                    const duration = Math.floor(value / 86400 / 1000);
                    return `${duration} ngày`;
                  }
                },
              },
            },
            action: {},
          },
          findAllOptionsFilter(options) {
            options.orderItems = [];
            options.orderItems.push({
              field: "disabled",
              type: "asc",
            });
            if (options.orderBy) {
              options.orderItems.push({
                field: options.orderBy,
                type: options.orderType,
              });
              options.orderBy = undefined;
              options.orderType = undefined;
            }
            return options;
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
              moveDepartment: {
                attrs: {},
                content: {
                  icon: "mdi-family-tree",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Chuyển phòng",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Chuyển phòng",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            nextDepartmentId: item.nextDepartmentId,
                            nextDepartmentStartTime: item.nextDepartmentStartTime,
                          });
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    nextDepartmentId: {
                                      type: "XAutocomplete",
                                      attrs: {
                                        label: "Phòng",
                                        required: true,
                                        "item-value": "_id",
                                        "item-text": "name",
                                        xOptions: {
                                          content: {
                                            async itemsLoader() {
                                              const { items } = await coreApiClient.call("departments", "findAll", {
                                                limit: -1,
                                              });
                                              return items;
                                            },
                                          },
                                        },
                                      },
                                    },
                                    nextDepartmentStartTime: {
                                      type: "XDateTimePicker",
                                      attrs: {
                                        label: "Ngày chuyển phòng",
                                        xOptions: {
                                          content: {
                                            resetable: true,
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "teams",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        nextDepartmentId: data.nextDepartmentId,
                                        nextDepartmentStartTime: data.nextDepartmentStartTime,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("teams", "findAll", options);
              if (items.length) {
                const ids = items.map((item) => item._id);
                const avgTvDurations = await coreApiClient.call("users", "getStatistic", {
                  payload: JSON.stringify({
                    findRequest: {
                      filters: [
                        {
                          key: "teamId",
                          operator: "in",
                          value: ids,
                        },
                        {
                          key: "tvTime",
                          operator: "greater_than",
                          value: 0,
                        },
                        {
                          key: "ctTime",
                          operator: "greater_than",
                          value: 0,
                        },
                      ],
                    },
                    aggregations: {
                      teams: {
                        type: "terms",
                        magicFields: {
                          id: "teamId",
                        },
                        subAggregations: {
                          avg: {
                            type: "avg",
                            magicField: {
                              funcName: "subtract",
                              fields: ["ctTime", "tvTime"],
                            },
                          },
                        },
                      },
                    },
                  }),
                });
                return [
                  items.map((item) => {
                    const teamInfo = avgTvDurations.teams[item._id];
                    if (teamInfo && teamInfo.info && teamInfo.info.avg) {
                      item.avgTvDuration = teamInfo.info.avg;
                    }
                    return item;
                  }),
                  count,
                ];
              }
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("teams", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "teams",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("teams", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              departmentId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Phòng",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("departments", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              disabled: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: [
                    { text: "Hoạt động", value: false },
                    { text: "Dừng hoạt động", value: true },
                  ],
                },
              },
              tmdt: {
                type: "boolean",
                attrs: {
                  label: "TMDT",
                },
              },
              startTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày bắt đầu",
                },
              },
            },
            insertForm: userManager.checkRole(["kt"]) && {
              content: {
                fieldNames: ["name", "description", "departmentId", "disabled", "tmdt", "startTime"],
              },
            },
            editForm: userManager.checkRole(["kt"]) && {
              content: {
                fieldNames: ["name", "description", "departmentId", "disabled", "tmdt", "startTime"],
              },
            },
            delete: {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
});
